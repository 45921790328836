import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8c5ca6fe = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _56075d6b = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _312b8225 = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _3968635f = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _6cb813e8 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _966f04fe = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _068d8128 = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _7f832736 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _4fb8b0eb = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _34043201 = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _6a26971a = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _1799475a = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _98c26944 = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _da56af44 = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _01aa798c = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _55309110 = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _2798d563 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _72e8d880 = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _0c50b942 = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _5fae86ba = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _f373c0fe = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _8c5ca6fe,
    name: "kits"
  }, {
    path: "/lojas",
    component: _56075d6b,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _312b8225,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _3968635f,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _6cb813e8,
    name: "motos"
  }, {
    path: "/reset",
    component: _966f04fe,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _068d8128,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _7f832736,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _4fb8b0eb,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _34043201,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _6a26971a,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _1799475a,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _98c26944,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _da56af44,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _01aa798c,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _55309110,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _2798d563,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _72e8d880,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _0c50b942,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _5fae86ba,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _f373c0fe,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
